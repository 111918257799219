import {
  Actions,
  LegTypes,
  OffersComputationOutputItemsStatuses,
  UserRegistrationSteps,
  UserRegistrationTypes,
  UserRoles,
} from '@shared/enums'

// @todo More meaningful name ???
export const SIMPLE_DATE_FORMAT = 'DD.MM.YYYY'

export const SIMPLE_TIME_FORMAT = 'HH:mm'

export const SIMPLE_DATE_AND_TIME_FORMAT = 'DD.MM.YYYY HH:mm'

export const SEMANTIC_DURATION_FORMAT = 'H[h] m[m]'

export const SIMPLE_DURATION_FORMAT = 'HH:mm'

export const DEFAULT_DISTANCE_UNIT = 'NM'

export const DEFAULT_NUMBER_FORMAT = '### ##0.##'

export const DEFAULT_PRICE_FORMAT_WITHOUT_CURRENCY = '### ##0.##'

export const DEFAULT_ORDER_BY = 'created_at'

export const DEFAULT_ORDER_DIRECTION = 'asc'

export const REQUEST_TRIP_ID_LENGTH = 6

export const REQUEST_TRIP_ID_PREFIX = 'STR-'

export const AVINODE_REQUEST_TRIP_ID_PREFIX = 'AVN-'

export const AVIAPAGES_REQUEST_TRIP_ID_PREFIX = 'AVP-'

export const WEB_REQUEST_TRIP_ID_PREFIX = 'WEB-'

export const INVALID_COMPUTATION_OUTPUT_ITEM_STATUSES = [
  OffersComputationOutputItemsStatuses.CapacityExceeded,
  OffersComputationOutputItemsStatuses.FlightRangeExceeded,
  OffersComputationOutputItemsStatuses.ScheduleConflict,
  /**
   * @todo [Tech] Deprecate OverlappingLeg status - remove from existing
   *              computations
   */
  OffersComputationOutputItemsStatuses.OverlappingLeg,
]

export const REQUIRED_LEG_TYPES: LegTypes[] = [
  LegTypes.Ferry,
  LegTypes.Occupied,
  LegTypes.Outage,
]

export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/

export const PERMISSIONS = new Map<Actions, UserRoles[]>([
  [
    Actions.ImportAirportFees,
    [UserRoles.CompanyAdmin, UserRoles.OperatorAdmin],
  ],
  [
    Actions.CreateAircraft,
    [UserRoles.CompanyAdmin, UserRoles.OperatorAdmin, UserRoles.Manager],
  ],
  [
    Actions.UpdateAircraft,
    [UserRoles.CompanyAdmin, UserRoles.OperatorAdmin, UserRoles.Manager],
  ],
  [
    Actions.DeleteAircraft,
    [UserRoles.CompanyAdmin, UserRoles.OperatorAdmin, UserRoles.Manager],
  ],
  [
    Actions.CopyAircraft,
    [UserRoles.CompanyAdmin, UserRoles.OperatorAdmin, UserRoles.Manager],
  ],

  [Actions.CreateCompany, [UserRoles.CompanyAdmin]],
  [Actions.UpdateCompany, [UserRoles.CompanyAdmin]],

  [Actions.CreateOperator, [UserRoles.CompanyAdmin]],
  [Actions.UpdateOperator, [UserRoles.CompanyAdmin, UserRoles.OperatorAdmin]],

  [Actions.ChangeUserStatus, [UserRoles.CompanyAdmin, UserRoles.OperatorAdmin]],
  [Actions.InviteUser, [UserRoles.CompanyAdmin, UserRoles.OperatorAdmin]],
  [
    Actions.ResendUserInvitation,
    [UserRoles.CompanyAdmin, UserRoles.OperatorAdmin],
  ],
  [Actions.UpdateUserRoles, [UserRoles.CompanyAdmin, UserRoles.OperatorAdmin]],
  [Actions.UpdateOtherUsers, [UserRoles.CompanyAdmin, UserRoles.OperatorAdmin]],

  [
    Actions.UpdateUserOperator,
    [UserRoles.CompanyAdmin, UserRoles.OperatorAdmin],
  ],
])

export const USER_ROLE_WEIGHTS: Record<UserRoles, number> = {
  [UserRoles.Operator]: 1,
  [UserRoles.Manager]: 2,
  [UserRoles.OperatorAdmin]: 3,
  [UserRoles.CompanyAdmin]: 4,
}

export const USER_REGISTRATION_HASH_QUERY_PARAMETER = 'hash'

export const userRegistrationStepTransitions: Record<
  UserRegistrationTypes,
  Record<UserRegistrationSteps, UserRegistrationSteps[]>
> = {
  [UserRegistrationTypes.UserRegistration]: {
    [UserRegistrationSteps.Initial]: [UserRegistrationSteps.UserRegistration],

    [UserRegistrationSteps.UserRegistration]: [UserRegistrationSteps.Initial],

    [UserRegistrationSteps.OperatorRegistration]: [],

    [UserRegistrationSteps.CompanyRegistration]: [],
  },

  [UserRegistrationTypes.CompanyRegistration]: {
    [UserRegistrationSteps.Initial]: [UserRegistrationSteps.UserRegistration],

    [UserRegistrationSteps.UserRegistration]: [
      UserRegistrationSteps.Initial,
      UserRegistrationSteps.CompanyRegistration,
    ],

    [UserRegistrationSteps.CompanyRegistration]: [
      UserRegistrationSteps.UserRegistration,
      UserRegistrationSteps.OperatorRegistration,
    ],

    [UserRegistrationSteps.OperatorRegistration]: [
      UserRegistrationSteps.CompanyRegistration,
    ],
  },
}
