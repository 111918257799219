export enum BusinessEnvironments {
  Local = 'local',
  Development = 'development',
  Staging = 'staging',
  Production = 'production',
}

export enum Queues {
  AvinodeConnectorGeneral = 'avinode_connector.general',
  AvinodeConnectorAircraftScheduleSync = 'avinode_connector.aircraft_schedule_sync',

  WorkerGeneral = 'worker.general',
  WorkerNewBooking = 'worker.new_booking',
  WorkerAvinodeAircraftScheduleSync = 'worker.avinode_aircraft_schedule_sync',
  WorkerAvinodeProcessor = 'worker.avinode_processor',
  WorkerDocumentGeneration = 'worker.document_generator',
}

export enum AvinodeConnectorGeneralQueueEvents {
  NewOutgoingResponse = 'new_outgoing_response',
  NewOutgoingChatMessage = 'new_outgoing_chat_message',
  RequestOutgoingUpdate = 'request_outgoing_update',
  ResponseOutgoingUpdate = 'response_outgoing_update',
  RegisterWebhook = 'register_webhook',
  DisableWebhook = 'disable_webhook',
  EnableWebhook = 'enable_webhook',
  DeclineRequestForQuote = 'decline_rfq',
  DeclineRequestForQuoteById = 'decline_rfq_by_id',
  QuoteRequestForQuote = 'quote_rfq',
  NewIncomingMessage = 'new_incoming_message',
  AvinodeWebhookRetrieval = 'avinode_webhook_retrieval',
}

export enum WorkerGeneralQueueEvents {
  NewComputation = 'new_computation',
  PostBookingScheduleSync = 'post_booking_schedule_sync',
  OfferScheduleSync = 'offer_schedule_sync',
  SendQuotationEmail = 'send_quotation_email',
  SendBookingEmail = 'send_booking_email',
  OfferCancellation = 'offer_cancellation',
  OfferRebooking = 'offer_rebooking',
  SendResetPasswordEmail = 'reset_password',
  SendInvitationEmail = 'send_invitation',
}

export enum WorkerAvinodeQueueEvents {
  IncomingRequestForQuoteUpdate = 'incoming_request_for_quote_request',
  IncomingTripRequestForQuoteMessage = 'incoming_trip_request_for_quote_message',
}

export enum NodeEnvironments {
  Development = 'development',
  Production = 'production',
}

export enum LegTypes { //
  Empty = 'empty',
  Occupied = 'occupied',
  Ferry = 'ferry',
  Removed = 'removed',
  Outage = 'outage',
}

export enum WarningType {
  Paid = 'paid',
  NotPaid = 'notPaid',
  All = 'all',
}

export enum UserStatuses {
  Invited = 'invited',
  Active = 'active',
  Disabled = 'disabled',
}

export enum UserRoles {
  Operator = 'operator',
  Manager = 'manager',
  CompanyAdmin = 'company-admin',
  OperatorAdmin = 'operator-admin',
}

export enum Actions {
  CreateFuelCost = 'CreateFuelCost',
  GetFuelCost = 'GetFuelCost',
  UpdateFuelCost = 'UpdateFuelCost',
  DeleteFuelCost = 'DeleteFuelCost',

  CreateOvernightFee = 'CreateOvernightFee',
  GetOvernightFee = 'GetOvernightFee',
  UpdateOvernightFee = 'UpdateOvernightFee',
  DeleteOvernightFee = 'DeleteOvernightFee',

  CreateAirportFee = 'CreateAirportFee',
  GetAirportFee = 'GetAirportFee',
  UpdateAirportFee = 'UpdateAirportFee',
  DeleteAirportFee = 'DeleteAirportFee',
  ImportAirportFees = 'ImportAirportFees',

  CreateCustomRoute = 'CreateCustomRoute',
  GetCustomRoute = 'GetCustomRoute',
  UpdateCustomRoute = 'UpdateCustomRoute',
  DeleteCustomRoute = 'DeleteCustomRoute',

  CreateAircraft = 'CreateAircraft',
  GetAircraft = 'GetAircraft',
  UpdateAircraft = 'UpdateAircraft',
  DeleteAircraft = 'DeleteAircraft',
  CopyAircraft = 'CopyAircraft',

  CreateClient = 'CreateClient',
  GetClient = 'GetClient',
  UpdateClient = 'UpdateClient',
  DeleteClient = 'DeleteClient',

  CreateContactPerson = 'CreateContactPerson',
  GetContactPerson = 'GetContactPerson',
  UpdateContactPerson = 'UpdateContactPerson',
  DeleteContactPerson = 'DeleteContactPerson',

  CreateCompany = 'CreateCompany',
  GetCompany = 'GetCompany',
  UpdateCompany = 'UpdateCompany',

  CreateOperator = 'CreateOperator',
  GetOperator = 'GetOperator',
  UpdateOperator = 'UpdateOperator',

  GetUser = 'GetUser',
  ChangeUserStatus = 'ChangeUserStatus',
  InviteUser = 'InviteUser',
  ResendUserInvitation = 'ResendUserInvitation',
  UpdateUserRoles = 'UpdateUserRoles',
  UpdateUserOperator = 'UpdateUserOperator',

  GetRequest = 'GetRequest',
  UpdateRequest = 'UpdateRequest',

  GetOffer = 'GetOffer',
  UpdateOffer = 'UpdateOffer',
  UpdateOfferStatus = 'UpdateOfferStatus',
  DeleteOffer = 'DeleteOffer',
  CancelOffer = 'CancelOffer',
  RebookOffer = 'RebookOffer',
  SendOfferByEmail = 'SendOfferByEmail',

  GetAirport = 'GetAirport',

  GetChat = 'GetChat',
  SendChatMessage = 'SendChatMessage',

  GetSchedule = 'GetSchedule',
  CreateOutage = 'CreateOutage',
  UpdateOutage = 'UpdateOutage',
  DeleteOutage = 'DeleteOutage',
  UpdateMarketplaceExtension = 'UpdateMarketplaceExtension',
  DeleteMarketplaceExtension = 'DeleteMarketplaceExtension',

  UpdateOtherUsers = 'UpdateOtherUsers',

  CreateDocument = 'CreateDocument',
  ReadDocument = 'ReadDocument',
}

export enum ComputationTypes {
  Offer = 'offer-computation',
  OfferRecalculation = 'offer-recalculation-computation',
  OfferBookedRecalculation = 'offer-booked-recalculation-computation',
  OfferCalculation = 'offer-calculation',
}

export enum ComputationStatuses {
  Created = 'created',
  Running = 'running',
  Finished = 'finished',
  Failure = 'failure',
}

export enum OfferStatuses { //
  New = 'new',
  Unhandled = 'unhandled',
  Draft = 'draft',
  Quoted = 'quoted',
  Booked = 'booked',
  BookedCancelled = 'booked-cancelled',
  Declined = 'declined',
  Cancelled = 'cancelled',
  Rejected = 'rejected',
  Storno = 'storno',
  Processing = 'processing',
}

export enum OfferTypes {
  Regular = 'regular',
  Cancellation = 'cancellation',
  Rebooking = 'rebooking',
}

export enum OfferFlags { //
  BeingRecalculated = 'being-recalculated',
  ProfitOpportunity = 'profit-opportunity',
  ProfitReduced = 'profit-reduced',
  ProfitLost = 'profit-lost',
  ToRequote = 'to-requote',
  Conflict = 'conflict',
  AircraftUnavailable = 'aircraft-unavailable',
  EmptyLeg = 'empty-leg',
  NewMessage = 'new-message',
}

export enum OffersComputationOutputItemsStatuses {
  GeneralError = 'general_error',
  OverlappingLeg = 'overlapping_leg',
  CapacityExceeded = 'capacity_exceeded',
  FlightRangeExceeded = 'flight_range_exceeded',
  ScheduleConflict = 'schedule_conflict',
  AirportNotFound = 'airport_not_found',
  Created = 'created',
}

export enum ScheduleSource {
  STRAFOS = 'strafos',
  FL3XX = 'fl3xx',
  LEON = 'leon',
}

export enum AvinodeRequestActions {
  Decline = 'decline',
  SubmitQuote = 'submitQuote',
  SearchInAvinode = 'searchInAvinode',
}

export enum EmailTemplateNames {
  ForgottenPassword = 'forgotten_password',
  Quotation = 'quotation',
  Booking = 'booking',
  Offer = 'offer',
  Invitation = 'invitation',
}

export enum EmailDateTypes {
  Departure = 'ETD',
  Arrival = 'ETA',
}

// @todo Remove in favor of CustomRoutesOrders
export enum CustomRoutesOrderBy {
  CreatedAt = 'created_at',
  DepartureAirport = 'departure_airport',
  ArrivalAirport = 'arrival_airport',
}

// TODO handle failures on Mailgun side (see https://documentation.mailgun.com/en/latest/api-webhooks.html#webhooks)
export enum EmailStatuses {
  Processing = 'processing',
  Queued = 'queued',
  Failed = 'failed',
}

export enum ChatMessageTypes {
  AvinodeMessage = 'avinode_message',
  SystemMessage = 'system_message',
  UserMessage = 'user_message',
  EmailSentConfirmationMessage = 'email_sent_confirmation_message',
}

// @todo Remove in favor of AirportFeesOrders
export enum AirportFeesOrderBy {
  CreatedAt = 'created_at',
  Airport = 'airport',
  Aircraft = 'aircraft',
}

export enum ContactPersonsOrderBy {
  CreatedAt = 'created_at',
  Name = 'name',
  Email = 'email',
  Phone = 'phone',
  MobilePhone = 'mobile_phone',
}

export enum DisplayWeekStartDays {
  Monday = 'Monday',
  Sunday = 'Sunday',
}

// TODO yet to be specified
export enum DisplayDateFormats {
  Czech = 'DD.MM.YYYY',
  British = 'DD/MM/YYYY',
  American = 'MM/DD/YYYY',
}

export enum DisplayTimeFormats {
  TwelveHours = 'hh:mm A',
  TwentyFourHours = 'HH:mm',
}

export enum DisplayTimeTypes {
  AirportLocalTime = 'AirportLocalTime',
  UTC = 'UTC',
}

export enum DisplayAirportIdentifications {
  ICAO = 'icao',
  IATA = 'iata',
}

export enum Routes {
  Index = '/',
  Login = '/login',
  LostPassword = '/lost-password',
  PasswordReset = '/reset-password',
  NewPassword = '/new-password',
  Requests = '/requests',
  Request = '/request/:requestId',
  Bookings = '/bookings',
  MyTeam = '/my-team',
  Fleet = '/fleet',
  CreateAircraft = '/aircraft/new',
  AircraftDetail = '/aircraft/:aircraftId',
  Airports = '/airports',
  AirportDetail = '/airport/:airportId',
  LiveMap = '/live-map',
  Reports = '/reports',
  Schedule = '/schedule',
  Settings = '/settings',
  Clients = '/clients',
  ClientDetail = '/clients/:clientId',
  Print = '/print/:type',
  UserRegistration = '/register',
  CallbackLeonSuccess = '/callback/leon/auth-success',
}

export enum UserRegistrationTypes {
  UserRegistration = 'user-registration',
  CompanyRegistration = 'company-registration',
}

export enum UserRegistrationSteps {
  Initial = 'initial',
  UserRegistration = 'user_registration',
  OperatorRegistration = 'operator_registration',
  CompanyRegistration = 'company_registration',
}

export enum DocumentStatus {
  PENDING = 'PENDING',
  GENERATING = 'GENERATING',
  CREATED = 'CREATED',
  ERROR = 'ERROR',
}

export enum DocumentFileType {
  PDF = 'pdf',
  DOCx = 'docx',
}

export enum DocumentType {
  CharterOffer = 'charter_offer',
  OwnerApproval = 'owner_approval',
  Invoice = 'invoice',
}

export enum Marketplace {
  avinode = 'avinode',
  aviapages = 'aviapages',
}
